<template>
	<div style="background-color: black; color: white">
		<div v-if="!userIsLoggedIn">
			<h1>This is the Guest Login page</h1>
		</div>
		<div v-if="serverBusy" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router";
export default {
	name: "GuestLogin",
	props: {
		playerState: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			guestAccessToken: null,
			guestRefreshToken: null,
			guestExpirationToken: null,
			busyText: "",
			userIsLoggedIn: false,
		};
	},
	mounted() {},
	created() {
		this.resolveQueryParams();
	},
	methods: {
		resolveQueryParams() {
			let location = window.location;
			let urlParams = null;

			if (location.hash) {
				location = new URL(location.href.replace("#/", ""));
				urlParams = new URLSearchParams(location.search);
			} else {
				urlParams = new URLSearchParams(location.search);
			}

			console.log(decodeURIComponent(urlParams));

			for (const [key, value] of urlParams) {
				if (key === "accessToken") this.guestAccessToken = value;
				if (key === "refreshToken") this.guestRefreshToken = value;
				if (key === "accessTokenExpiration") this.guestTokenExpiration = value;
			}
			if (this.guestAccessToken != null) {
				this.handleGuestLogin();
			}
		},

		async handleGuestLogin() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.checkingCradentials;

			let body = {
				OldAccessToken: this.guestAccessToken,
				RefreshToken: this.guestRefreshToken,
			};

			let requestUrl = new URL("api/v1/authentication/refresh", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-TYpe", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);
				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					return;
				}

				let dataJson = await response.json();

				dataJson.loggedIn = true;
				dataJson.casinoBalances = (await this.getCurrentBalances(dataJson)) || {};

				this.serverBusy = false;
				this.busyText = "";
				this.userIsLoggedIn = true;
				this.eventBus.emit("updatePlayerState", dataJson);
				this.eventBus.emit("updateUserProfile");
				this.eventBus.emit("updateBalance", dataJson);

				setTimeout(() => {
					// This is to fix a race condition
					// With some more testing we might find we can reduce the delay to a half second or less.
					// It just needed some time for the data to propagate down the components.
					// this.eventBus.emit("updateCasinosIncludingDeleted", this.status.ok);
					if (!fetchStatus.message == 200) this.eventBus.emit("updateStatus", fetchStatus);
					router.push("/webPayCashIn");
				}, 1000);
			} catch (e) {
				console.error(e);
			}

		},

		async getCurrentBalances(accountData) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${accountData.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/funds", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				dataJson.forEach((balance) => {
					let matchedByCasinoId = this.casinoList.filter((casino) => balance.casinoId == casino.id)[0];
					// Insert an instance of the currency tool class specific to each casino's in-game currency rules.
					balance.currencyTool = new CurrencyTool(matchedByCasinoId.currencyInfo, this.localeString);
				});

				return dataJson;
			} catch (e) {
				console.error(e);
			}
		},
	}
}
</script>

<style scoped></style>
