<template>
	<header>
		<img v-if="countryCode === 'MX'" class="header-logo-sm" :src="`${locationOrigin}/styleOverrides/encore_online.png`" alt="encoreonline.mx" />
		<!-- <h1 v-if="playerState.displayName && countryCode === 'MX' && !isMobile">Cuenta de {{ playerState.displayName }}</h1> -->
		<h1 v-if="playerState.displayName && countryCode === 'US'">{{ playerState.displayName }}'s {{ languageStrings.account }}</h1>
		<h1 v-if="!playerState.displayName && countryCode !== 'MX'">{{ languageStrings.playerAccount }}</h1>
	</header>
	<div class="notifications curved-border" v-if="notificationList.length > 0">
		<button class="dismiss-notify btn" title="Dismiss all notifications" @click="dismissNotifications()"><p class="dismiss-notifications-x">x</p></button> 
		<table class="notifications-table curved-border">
			<tr class="curved-border">
				<th>{{ languageStrings.time }}</th>
				<th>{{ languageStrings.notification }}</th>
			</tr>
			<tr class="curved-border" v-for="(item, index) in notificationList" :key="index" :class="!item.ok ? 'error' : 'ok'">
				<td>{{ item.createdTimeString }}</td>
				<td v-if="item.message">{{ item.message }}</td>
				<td v-else>{{ item.code }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "Header",
	inheritAttrs: false,
	props: {
		playerState: Object,
		languageStrings: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			locationOrigin: "",
			notificationList: [],
			accountLockNotification: false,
		};
	},
	created() {
		this.eventBus.on("updateStatus", (payload) => {
			let notify = Object.assign({}, payload);
			let date = new Date();
			notify.created = date.getTime();
			notify.createdTimeString = date.toLocaleTimeString(this.localeString);
			notify.createdDateTimeString = date.toLocaleTimeString(this.localeString, this.dateOptions);
			if (notify.message !== "Session from cache") {
				this.notificationList.push(notify);
			}
			let arrayUnique = this.notificationList.filter((obj, index) => {
				// the 423 server response code was getting thrown multiple times
				// filter list down to not showduplicate messages
				return index === this.notificationList.findIndex((o) => obj.message === o.message);
			});
			this.notificationList = arrayUnique;
		});
	},
	mounted() {
		this.locationOrigin = window.location.origin;
		setInterval(() => {
			this.updateNotifications();
		}, 1000);
	},
	methods: {
		dismissNotifications() {
			this.notificationList = [];
		},
		updateNotifications() {
			let date = new Date();
			// notification box cleanup
			if (this.notificationList.length > 0) {
				let timeOffset = date.getTime() - 15000;
				this.notificationList = this.notificationList.filter((notification) => {
					return notification.created > timeOffset;
				});
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	text-align: center;
}

header {
	position: fixed;
	top: 0;
	width: 100%;
	padding: 20px 0;
	background-color: #434250;
	color: #fff;
}

header h1 {
	margin: 0;
}

.bank-status {
	position: absolute;
	top: 15px;
	left: 15px;
	padding: 10px 15px;
	border-radius: 6px;
	font-weight: 700;
	transition: background-color 0.3s ease, color 0.3s ease;
	z-index: 500;
}

#loading-icon {
	display: none;
	content: "";
	position: absolute;
	left: 15px;
	bottom: 15px;
	height: 32px;
	width: 32px;
	border: 2px solid;
	border-radius: 100%;
	border-color: red white blue black;
	animation: loader 0.5s linear infinite;
}

#loading-icon.loading {
	display: block;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.status-message {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}

.status-message {
	position: absolute;
	top: 15px;
	left: 15px;
	padding: 10px 15px;
	border-radius: 15px;
	font-weight: 700;
	transition: background-color 0.3s ease, color 0.3s ease;
	z-index: 500;
}

.status-message.ok,
.notifications tr.ok {
	background-color: white;
	color: #6305C9;
}

.status-message.error,
.notifications tr.error {
	background-color: #f00;
	color: #fff;
}

.status-message span {
	display: block;
}

.notifications {
	position: fixed;
	margin: 15px;
	padding: 15px;
	top: unset;
	left: 0;
	bottom: 0;
	overflow: hidden auto;
	max-height: calc(100vh - 10em);
	background: rgb(0 0 0 / 75%);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	z-index: 50000;
}

.notifications td {
	padding: 10px;
}

.dismiss-notify {
	position: absolute;
	/* right: 15px;
	top: 9px; */
	left: unset;
	font-size: 1.25em;
	font-weight: bold;
	user-select: none;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	border-color: transparent;
}

.dismiss-notifications-x {
	font-size: 13px;
	margin-bottom: 7px;
	margin-top: 1px;
	margin-left: 2px;
	font-weight: bold;
}

.notifications-table {
	border-spacing: 0 0.7em;
}

tr td:first-of-type {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	margin-left: 10px;
}

tr td:last-of-type {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

@media (min-width: 768px) {
	header {
		padding: 30px;
	}

	.notifications {
		top: 0;
		bottom: unset;
	}
}
</style>
